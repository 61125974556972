@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@font-face {
  font-family: Satoshi-light;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Light);
  font-weight: 300;
}
@font-face {
  font-family: Satoshi-Regular;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Regular);
  font-weight: 400;
}
@font-face {
  font-family: Satoshi-Medium;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Medium);
  font-weight: 500;
}
@font-face {
  font-family: Satoshi-bold;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Bold);
  font-weight: 700;
}
@font-face {
  font-family: Satoshi-black;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Black);
  font-weight: 900;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Satoshi-Medium;
}

button:disabled {
  cursor: not-allowed !important;
}

body {
  background-color: #f2f2f2;
  background-size: cover;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  transition: all 0.4s;
  color: #000;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  max-width: 1450px;
  margin: 0 auto;
  width: 96%;
}

nav {
  padding: 10px 0;
  z-index: 100;
  position: relative;
}
nav .logo {
  width: 140px;
}
nav .logo img {
  width: 100%;
}
nav ul {
  display: flex;
  align-items: center;
  gap: 50px;
}
nav ul li {
  font-size: 18px;
  cursor: pointer;
  padding: 20px 0;
}
nav .dropwown {
  position: relative;
}
nav .dropwown ul {
  background-color: #fff;
  position: absolute;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  flex-direction: column;
  left: -96px;
  top: 100%;
  border-radius: 10px;
  gap: 0;
  padding: 10px 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY();
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  transition: all 0.4s;
}
nav .dropwown ul li {
  padding: 0px 52px;
  font-size: 16px;
}
nav .dropwown ul li a {
  color: #000;
  padding: 15px 0;
  display: block;
}
nav .dropwown ul li a:hover {
  color: #474bff;
}
nav .dropwown:hover ul {
  opacity: 1;
  visibility: visible;
}

.heading {
  font-size: 28px;
  text-align: center;
  padding: 30px 0;
}

.imgcon form {
  flex: 0.3;
  position: relative;
  border-radius: 10px;
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: center;
  height: 150px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 2px dashed grey;
  transition: all 0.4s;
}
.imgcon form img {
  width: 50px;
}
.imgcon form input {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}
.imgcon form label {
  position: relative;
  display: block;
  text-align: center;
  font-size: 18px;
  font-family: Satoshi-Medium;
  color: grey;
}
.imgcon form:hover {
  background-color: rgb(248, 249, 251);
  border: 2px dashed blue;
  filter: blur(2px);
}

.cont {
  margin-top: 30px;
  gap: 50px;
  align-items: flex-start;
}
.cont .previmg {
  flex: 0.25;
  max-height: 300px;
}
.cont .previmg img {
  width: 100%;
  max-height: 300px;
  border-radius: 10px;
  -o-object-fit: cover;
     object-fit: cover;
}
.cont .output {
  flex: 0.75;
}
.cont .output textarea {
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  min-width: 100%;
  max-width: 1050px;
  font-size: 15px;
  color: grey;
  background-color: transparent;
  overflow-x: hidden;
  min-height: 300px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.cont .output textarea::-webkit-scrollbar {
  width: 4px;
  height: 100%;
}
.cont .output textarea::-webkit-scrollbar-thumb {
  background-color: grey;
}
.cont .output .btngrp {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.cont .output .btngrp button {
  width: 250px;
  height: 50px;
  border-radius: 30px;
  border: 0;
  background-color: #474bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.cont .output .btngrp button:disabled {
  background-color: rgba(71, 74, 255, 0.3843137255);
  cursor: not-allowed;
}

.whois form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 150px;
}
.whois form input {
  width: 500px;
  height: 60px;
  border-radius: 10px;
  padding: 0 20px;
  font-size: 16px;
  border: 0.5px solid #000;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.whois form button {
  width: 250px;
  height: 45px;
  border-radius: 10px;
  border: 0;
  font-size: 16px;
  cursor: pointer;
  background-color: #000;
  color: white;
}
.whois .content {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.whois .content .loader {
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.whois .content .spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgb(168, 168, 168);
  border-left-color: #000;
  border-radius: 50%;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}
@-webkit-keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.whois .details {
  margin-top: 30px;
  margin-bottom: 50px;
  padding: 0 30px;
  width: 70%;
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.whois .details p {
  padding: 30px 0;
  font-size: 16px;
  line-height: 30px;
}

.loader {
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgb(168, 168, 168);
  border-left-color: #000;
  border-radius: 50%;
  -webkit-animation: spin 1s infinite linear;
          animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.options {
  margin: 50px auto;
  display: flex;
  justify-content: center;
  max-width: 500px;
  background-color: #d3d3d3;
  border-radius: 10px;
  overflow: hidden;
}
.options li {
  width: 50%;
  padding: 15px 20px;
  text-align: center;
  cursor: pointer;
}
.options .active {
  background-color: #000;
  color: #fff;
}

.doamincheck .doamin_box {
  margin: 50px auto;
  margin-top: 0;
  padding: 20px 30px;
  width: 70%;
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding-bottom: 20px;
}
.doamincheck .doamin_box button {
  width: 200px;
  height: 45px;
  border: 0;
  display: block;
  margin: 0px auto;
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
  background-color: #f2f2f2;
}
.doamincheck .doamin_box button:hover {
  background-color: #000;
  color: #fff;
}
.doamincheck .doamin_box .box {
  display: flex;
  padding: 20px 0;
  justify-content: space-between;
  align-items: center;
}
.doamincheck .doamin_box .box .left {
  display: flex;
  gap: 30px;
}
.doamincheck .doamin_box .box .left h2 {
  font-size: 22px;
  font-family: Satoshi-Regular;
}
.doamincheck .doamin_box .box .left img {
  margin-top: 5px;
}
.doamincheck .doamin_box .right .price {
  font-family: Satoshi-bold;
  font-size: 18px;
  font-weight: 700;
}
.doamincheck .doamin_box .right .price .notavilable {
  color: red;
  font-size: 16px;
  font-family: Satoshi-Regular;
}

.suggestions .sugbox {
  margin: 50px auto;
  margin-top: 0;
  padding: 20px 30px;
  width: 70%;
  border-radius: 10px;
  background: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.suggestions .sug_single {
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.suggestions .sug_single h2 {
  font-size: 22px;
  font-family: Satoshi-Regular;
}
.suggestions .sug_single p {
  font-family: Satoshi-bold;
  font-size: 18px;
  font-weight: 700;
}

.twoprt {
  display: flex;
}
.twoprt form {
  width: 49%;
}
.twoprt form textarea {
  width: 100%;
}
.twoprt textarea {
  resize: none;
  width: 49%;
  height: 400px;
  border-radius: 10px;
  padding: 20px;
  font-size: 16px;
}

.result {
  background: #fff;
  border-radius: 10px;
  max-width: 500px;
  margin: 50px auto 0;
  padding: 0 0 0 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: hidden;
  display: flex;
}
.result .inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.result span {
  display: inline-block;
  padding: 18px 0;
}
.result button {
  width: 150px;
  height: 100%;
  border: 0;
  background-color: #474bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.result button:hover {
  background-color: #000;
}
.result button:disabled {
  background-color: rgba(71, 74, 255, 0.3960784314);
}

.pleasewait {
  padding: 100px 0;
  text-align: center;
}

.mapbg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  background-image: url(https://ik.imagekit.io/Suresh/API/svg/Word_1qpwPSnuk.svg);
  background-size: contain;
  z-index: -100;
  filter: blur(4px);
}

.ipcontent {
  width: 100%;
  height: calc(100vh - 95px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.ipcontent .box {
  max-width: 400px;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 50px 25px 30px;
  font-family: "Poppins", sans-serif;
  box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;
}
.ipcontent .box img {
  width: 50px;
  min-height: 39px;
}
.ipcontent .box h2 {
  margin: 15px 0;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: grey;
  min-width: 310px;
}
.ipcontent .box h2 b {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  text-transform: capitalize;
  color: #000;
}

.attr {
  position: absolute;
  background-color: #fff;
  right: 20px;
  bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.coincontent {
  margin-top: 30px;
}
.coincontent .notfound {
  padding: 50px;
  max-width: 400px;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.coincontent ul {
  max-width: 400px;
  margin: 0 auto;
}
.coincontent ul li {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.coincontent ul li .icon {
  width: 40px;
  margin-bottom: 20px;
}
.coincontent ul li .change {
  font-size: 14px;
}
.coincontent ul li #minus {
  color: red;
}
.coincontent ul li #plus {
  color: green;
}
.coincontent ul li h2 {
  font-size: 18px;
  text-transform: uppercase;
}
.coincontent ul li .label {
  display: flex;
  align-items: center;
  gap: 15px;
}
.coincontent ul li h3 {
  font-family: Satoshi-Regular;
  color: gray;
  margin: 10px 0;
  font-size: 16px;
}
.coincontent ul li h3 span {
  font-family: Satoshi-Medium;
  font-size: 15px;
  color: #000;
}

.coinsearcher {
  max-width: 400px;
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;
}
.coinsearcher form {
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  position: relative;
}
.coinsearcher form button {
  background-color: #fff;
  border: 0;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
.coinsearcher form input {
  padding: 0 20px;
  width: 100%;
  background-color: transparent;
  border: 0;
  outline: 0;
  height: 50px;
  font-size: 16px;
  transition: all 0.4s;
}

.coinsearcher form input:focus {
  outline: 2px solid;
  outline-offset: -2px;
  border-radius: 5px;
}

.viewport_wrapper {
  height: calc(100vh - 90px);
  display: grid;
  place-content: center;
}
.viewport_wrapper .viewport {
  width: 400px;
  height: 250px;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  font-weight: 400;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 35px;
}
.viewport_wrapper .valus {
  font-size: 24px;
  font-weight: 600;
}
.viewport_wrapper .valus span {
  font-size: 40px;
  font-weight: 700;
}

.tb_bg {
  position: absolute;
  left: 0;
  width: 100%;
  left: 0;
  bottom: 0;
  height: calc(100vh - 90px);
  z-index: -10;
  -o-object-fit: cover;
     object-fit: cover;
  filter: brightness(0.2);
  display: none;
}

.tv {
  margin-bottom: 40px;
}
.tv .card_np {
  padding: 0;
  overflow: hidden;
  margin-bottom: 0;
  display: flex;
  gap: 30px;
  padding-right: 20px;
}
.tv .card_np .sw_img {
  max-height: 350px;
}
.tv .card_np .sw_img span {
  display: flex;
}
.tv .card_np .sw_img img {
  width: 280px;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.tv .card_np .cntt {
  padding: 30px 0;
}
.tv .card_np .cntt h2 {
  font-size: 28px;
}
.tv .card_np .cntt h3 {
  font-size: 20px;
  margin: 10px 0;
  display: flex;
  align-items: flex-end;
  gap: 15px;
}
.tv .card_np .cntt h3 img {
  width: 30px;
}
.tv .card_np .cntt .genres {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}
.tv .card_np .cntt .genres h3 {
  line-height: normal;
  height: auto;
  margin: 0;
}
.tv .card_np .cntt .genres p {
  padding: 0;
  line-height: normal;
  font-size: 18px;
}
.tv .card_np .cntt .summury {
  padding: 0;
  margin-top: 15px;
  max-height: 144px;
  overflow-y: scroll;
}
.tv .card_np .cntt .summury p {
  padding: 0;
  font-size: 18px;
}
.tv .card_np .cntt .summury::-webkit-scrollbar {
  display: none;
}
.tv .notfound {
  padding: 50px;
  max-width: 400px;
  background-color: #fff;
  margin: 0 auto;
  text-align: center;
  width: 80%;
  margin-top: 50px;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 50px;
}

.tool {
  width: auto;
  height: 220px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}
.tool a {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 30px;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.tool img {
  width: 70px;
}
.tool h2 {
  font-size: 24px;
  color: #000;
}
.tool span {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.547);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  transition: all 0.3s;
}
.tool span svg {
  width: 50px;
}

.tool:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tool:hover span {
  opacity: 1;
}

@media (max-width: 999px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .whois .details {
    width: 85%;
  }
}
@media (max-width: 767px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .flex {
    flex-direction: column;
    gap: 40px;
  }
  .cont .previmg, .cont .output {
    flex: auto;
    width: 100%;
    max-width: none;
  }
  .heading {
    padding-top: 40px;
  }
  .whois form input {
    width: 100%;
  }
  .options li {
    padding: 10px;
    font-size: 15px;
  }
  .doamincheck .doamin_box, .suggestions .sugbox, .whois .details {
    width: 100%;
    padding: 20px;
  }
  .whois .details p {
    padding: 0;
  }
  .ipcontent, .viewport_wrapper {
    height: calc(100vh - 160px);
  }
  .doamincheck .doamin_box .box .left h2 {
    font-size: 18px;
  }
  .result {
    padding-left: 0;
  }
  .result button {
    width: 100px;
  }
  .whois form input {
    height: 53px;
  }
  .result span {
    padding: 15px 0 15px 10px;
  }
  .viewport_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .coinsearcher, .viewport_wrapper .viewport {
    width: 96%;
  }
  .tv .card_np {
    flex-direction: column;
    padding: 0;
  }
  .tv .card_np .sw_img, .tv .card_np .sw_img img {
    width: 100%;
    max-height: none;
  }
  .tv .card_np .cntt {
    padding: 20px;
  }
  .tv .card_np .cntt h2 {
    font-size: 24px;
  }
  .tv .card_np .cntt h3 {
    font-size: 18px;
  }
  .tv .card_np .cntt .summury p {
    font-size: 16px;
  }
}
@media (max-width: 550px) {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}/*# sourceMappingURL=App.css.map */