@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");

@font-face {
  font-family: Satoshi-light;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Light);
  font-weight: 300;
}
@font-face {
  font-family: Satoshi-Regular;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Regular);
  font-weight: 400;
}
@font-face {
  font-family: Satoshi-Medium;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Medium);
  font-weight: 500;
}
@font-face {
  font-family: Satoshi-bold;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Bold);
  font-weight: 700;
}
@font-face {
  font-family: Satoshi-black;
  src: url(https://ik.imagekit.io/Suresh/Fonts/Satoshi/Satoshi-Black);
  font-weight: 900;
}

@mixin slight {
  font-family: Satoshi-light;
}

@mixin sregular {
  font-family: Satoshi-Regular;
}

@mixin smedium {
  font-family: Satoshi-Medium;
}

@mixin sbold {
  font-family: Satoshi-bold;
}

@mixin sblack {
  font-family: Satoshi-black;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @include smedium();
}

button:disabled {
  cursor: not-allowed !important;
}

body {
  background-color: #f2f2f2;
  // background-image: url(https://ik.imagekit.io/Suresh/API/svg/Word_1qpwPSnuk.svg);
  background-size: cover;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  transition: all 0.4s;
  color: #000;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  max-width: 1450px;
  margin: 0 auto;
  width: 96%;
}

nav {
  padding: 10px 0;
  z-index: 100;
  position: relative;

  .logo {
    width: 140px;

    img {
      width: 100%;
    }
  }

  ul {
    display: flex;
    align-items: center;
    gap: 50px;

    li {
      font-size: 18px;
      cursor: pointer;
      padding: 20px 0;
    }
  }

  .dropwown {
    position: relative;

    ul {
      background-color: #fff;
      position: absolute;
      width: max-content;
      flex-direction: column;
      left: -96px;
      top: 100%;
      border-radius: 10px;
      gap: 0;
      padding: 10px 0;
      opacity: 0;
      visibility: hidden;
      transform: translateY();
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: all 0.4s;

      li {
        padding: 0px 52px;
        font-size: 16px;

        a {
          color: #000;
          padding: 15px 0;
          display: block;
        }
        a:hover {
          color: #474bff;
        }
      }
    }
  }

  .dropwown:hover ul {
    opacity: 1;
    visibility: visible;
  }
}

.heading {
  font-size: 28px;
  text-align: center;
  padding: 30px 0;
}

// img2base css

.imgcon {
  form {
    flex: 0.3;
    // background-color: red;
    position: relative;
    border-radius: 10px;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    height: 150px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 2px dashed grey;
    transition: all 0.4s;

    img {
      width: 50px;
    }

    input {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
    }

    label {
      position: relative;
      display: block;
      text-align: center;
      font-size: 18px;
      @include smedium();
      color: grey;
    }
  }

  form:hover {
    background-color: rgb(248, 249, 251);
    border: 2px dashed blue;
    filter: blur(2px);
  }
}

.cont {
  margin-top: 30px;
  gap: 50px;
  align-items: flex-start;

  .previmg {
    flex: 0.25;
    max-height: 300px;

    img {
      width: 100%;
      max-height: 300px;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .output {
    flex: 0.75;

    textarea {
      padding: 20px;
      width: 100%;
      border-radius: 10px;
      min-width: 100%;
      max-width: 1050px;
      font-size: 15px;
      color: grey;
      background-color: transparent;
      overflow-x: hidden;
      min-height: 300px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    textarea::-webkit-scrollbar {
      width: 4px;
      height: 100%;
    }
    textarea::-webkit-scrollbar-thumb {
      background-color: grey;
    }

    .btngrp {
      margin-top: 20px;
      display: flex;
      align-items: center;

      button {
        width: 250px;
        height: 50px;
        border-radius: 30px;
        border: 0;
        background-color: #474bff;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
      }
      button:disabled {
        background-color: #474aff62;
        cursor: not-allowed;
      }
    }
  }
}

// whois css here

.whois {
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-top: 150px;

    input {
      width: 500px;
      height: 60px;
      border-radius: 10px;
      padding: 0 20px;
      font-size: 16px;
      border: 0.5px solid #000;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }

    button {
      width: 250px;
      height: 45px;
      border-radius: 10px;
      border: 0;
      font-size: 16px;
      cursor: pointer;
      background-color: #000;
      color: #ffff;
    }
  }

  .content {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .loader {
      width: 100%;
      display: flex;
      height: 100px;
      justify-content: center;
      align-items: center;
      margin-top: 100px;
    }

    .spinner {
      width: 50px;
      height: 50px;
      border: 4px solid rgb(168, 168, 168);
      border-left-color: #000;
      border-radius: 50%;
      animation: spin 1s infinite linear;
    }

    @keyframes spin {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .details {
    margin-top: 30px;
    margin-bottom: 50px;
    padding: 0 30px;
    width: 70%;
    border-radius: 10px;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    p {
      padding: 30px 0;
      font-size: 16px;
      line-height: 30px;
    }
  }
}

// doamin cheker css

.loader {
  width: 100%;
  display: flex;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgb(168, 168, 168);
  border-left-color: #000;
  border-radius: 50%;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.options {
  margin: 50px auto;
  display: flex;
  justify-content: center;
  max-width: 500px;
  background-color: #d3d3d3;
  border-radius: 10px;
  overflow: hidden;

  li {
    width: 50%;
    padding: 15px 20px;
    text-align: center;
    cursor: pointer;
  }
  .active {
    background-color: #000;
    color: #fff;
  }
}

.doamincheck {
  .doamin_box {
    margin: 50px auto;
    margin-top: 0;
    padding: 20px 30px;
    width: 70%;
    border-radius: 10px;
    background: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding-bottom: 20px;

    button {
      width: 200px;
      height: 45px;
      border: 0;
      display: block;
      margin: 0px auto;
      cursor: pointer;
      font-size: 16px;
      border-radius: 10px;
      background-color: #f2f2f2;
    }
    button:hover {
      background-color: #000;
      color: #fff;
    }

    .box {
      display: flex;
      padding: 20px 0;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        gap: 30px;
        h2 {
          font-size: 22px;
          @include sregular();
        }

        img {
          margin-top: 5px;
        }
      }
    }

    .right {
      .price {
        @include sbold();
        font-size: 18px;
        font-weight: 700;

        .notavilable {
          color: red;
          font-size: 16px;
          @include sregular();
        }
      }
    }
  }
}

.suggestions {
  .sugbox {
    margin: 50px auto;
    margin-top: 0;
    padding: 20px 30px;
    width: 70%;
    border-radius: 10px;
    background: #fff;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  }

  .sug_single {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      font-size: 22px;
      font-family: Satoshi-Regular;
    }

    p {
      font-family: Satoshi-bold;
      font-size: 18px;
      font-weight: 700;
    }
  }
}

// html to raw css

.twoprt {
  display: flex;

  form {
    width: 49%;

    textarea {
      width: 100%;
    }
  }

  textarea {
    resize: none;
    width: 49%;
    height: 400px;
    border-radius: 10px;
    padding: 20px;
    font-size: 16px;
  }
}

// link shorter

.result {
  background: #fff;
  border-radius: 10px;
  max-width: 500px;
  margin: 50px auto 0;
  padding: 0 0 0 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  overflow: hidden;
  display: flex;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  span {
    display: inline-block;
    padding: 18px 0;
  }

  button {
    width: 150px;
    height: 100%;
    border: 0;
    background-color: #474bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }
  button:hover {
    background-color: #000;
  }
  button:disabled {
    background-color: #474aff65;
  }
}

.pleasewait {
  padding: 100px 0;
  text-align: center;
}

// ip info

.mapbg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  background-image: url(https://ik.imagekit.io/Suresh/API/svg/Word_1qpwPSnuk.svg);
  background-size: contain;
  z-index: -100;
  filter: blur(4px);
}

.ipcontent {
  width: 100%;
  height: calc(100vh - 95px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .box {
    max-width: 400px;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 50px 25px 30px;
    font-family: "Poppins", sans-serif;
    box-shadow: rgba(3, 102, 214, 0.3) 0px 0px 0px 3px;

    img {
      width: 50px;
      min-height: 39px;
    }

    h2 {
      margin: 15px 0;
      font-size: 16px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      color: grey;
      min-width: 310px;

      b {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        text-transform: capitalize;
        color: #000;
      }
    }
  }
}

.attr {
  position: absolute;
  background-color: #fff;
  right: 20px;
  bottom: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

// crypto

.coincontent {
  margin-top: 30px;

  .notfound {
    padding: 50px;
    max-width: 400px;
    background-color: #fff;
    margin: 0 auto;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  ul {
    max-width: 400px;
    margin: 0 auto;

    li {
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      .icon {
        width: 40px;
        margin-bottom: 20px;
      }

      .change {
        font-size: 14px;
      }

      #minus {
        color: red;
      }

      #plus {
        color: green;
      }

      h2 {
        font-size: 18px;
        text-transform: uppercase;
      }

      .label {
        display: flex;
        align-items: center;
        gap: 15px;
      }

      h3 {
        font-family: Satoshi-Regular;
        color: gray;
        margin: 10px 0;
        font-size: 16px;

        span {
          font-family: Satoshi-Medium;
          font-size: 15px;
          color: #000;
        }
      }
    }
  }
}

.coinsearcher {
  max-width: 400px;
  width: 400px;
  margin: 0 auto;
  margin-top: 40px;

  form {
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    position: relative;

    button {
      background-color: #fff;
      border: 0;
      position: absolute;
      right: 20px;
      cursor: pointer;
    }

    input {
      padding: 0 20px;
      width: 100%;
      background-color: transparent;
      border: 0;
      outline: 0;
      height: 50px;
      font-size: 16px;
      transition: all 0.4s;
    }
  }
}

.coinsearcher form input:focus {
  outline: 2px solid;
  outline-offset: -2px;
  border-radius: 5px;
}

.viewport_wrapper {
  height: calc(100vh - 90px);
  display: grid;
  place-content: center;

  .viewport {
    width: 400px;
    height: 250px;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-weight: 400;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 35px;
  }

  .valus {
    font-size: 24px;
    font-weight: 600;

    span {
      font-size: 40px;
      font-weight: 700;
    }
  }
}

// tv show css

.tb_bg {
  position: absolute;
  left: 0;
  width: 100%;
  left: 0;
  bottom: 0;
  height: calc(100vh - 90px);
  z-index: -10;
  object-fit: cover;
  filter: brightness(0.2);
  display: none;
}

.tv {
  margin-bottom: 40px;

  .card_np {
    padding: 0;
    overflow: hidden;
    margin-bottom: 0;
    display: flex;
    gap: 30px;
    padding-right: 20px;

    .sw_img {
      max-height: 350px;

      span {
        display: flex;
      }

      img {
        width: 280px;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .cntt {
      padding: 30px 0;

      h2 {
        font-size: 28px;
      }

      h3 {
        font-size: 20px;
        margin: 10px 0;
        display: flex;
        align-items: flex-end;
        gap: 15px;

        img {
          width: 30px;
        }
      }

      .genres {
        display: flex;
        gap: 20px;
        align-items: flex-end;

        h3 {
          line-height: normal;
          height: auto;
          margin: 0;
        }

        p {
          padding: 0;
          line-height: normal;
          font-size: 18px;
        }
      }

      .summury {
        padding: 0;
        margin-top: 15px;
        max-height: 144px;
        overflow-y: scroll;

        p {
          padding: 0;
          font-size: 18px;
        }
      }

      .summury::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .notfound {
    padding: 50px;
    max-width: 400px;
    background-color: #fff;
    margin: 0 auto;
    text-align: center;
    width: 80%;
    margin-top: 50px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}

// homepage tools section

.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 50px;
}

.tool {
  width: auto;
  height: 220px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  a {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 30px;
    flex-direction: column;
    height: 100%;
    position: relative;
  }

  img {
    width: 70px;
  }

  h2 {
    font-size: 24px;
    color: #000;
  }

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.547);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: all 0.3s;

    svg {
      width: 50px;
    }
  }
}

.tool:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.tool:hover span {
  opacity: 1;
}

@media (max-width:999px) {
  
  .grid{
    grid-template-columns: repeat(3,1fr);
  }

  .whois .details {
    width: 85%;
  }

}

@media (max-width:767px) {
  
  .grid{
    grid-template-columns: repeat(2,1fr);
  }

  .flex{
    flex-direction: column;
    gap: 40px;
  }
  .cont .previmg,.cont .output{
    flex: auto;
    width: 100%;
    max-width: none;
  }

  .heading{
    padding-top: 40px;
  }
  
  .whois form input{
    width: 100%;
  }

  .options li{
    padding: 10px;
    font-size: 15px;
  }

  .doamincheck .doamin_box,.suggestions .sugbox,.whois .details{
    width: 100%;
    padding: 20px;
  }

  .whois .details p{
    padding: 0;
  }



  .ipcontent,.viewport_wrapper {
    height: calc(100vh - 160px);
}

  .doamincheck .doamin_box .box .left h2 {
  font-size: 18px;
}

.result{
  padding-left: 0;
}

.result button{
  width: 100px;
}

.whois form input {
height: 53px;
}

.result span{
  padding: 15px 0 15px 10px;
}

.viewport_wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.coinsearcher,.viewport_wrapper .viewport{
  width: 96%;
}

.tv .card_np{
  flex-direction: column;
  padding: 0;
}

.tv .card_np .sw_img,.tv .card_np .sw_img img{
  width: 100%;
  max-height: none;
}

.tv .card_np .cntt{
  padding: 20px;
}

.tv .card_np .cntt h2 {
font-size: 24px;
}

.tv .card_np .cntt h3{
  font-size: 18px;
}

.tv .card_np .cntt .summury p {
  font-size: 16px;
}

}

@media (max-width:550px) {
  
  .grid{
    grid-template-columns: repeat(1,1fr);
  }

}
